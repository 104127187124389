<template>
  <action-edit
    :action-url="`product-qualities/${item.id}`"
    :item="item"
    button-label="Editeaza Calitatea"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.multiplier" label="Coeficient inmultire"/>
        <error-messages :errors="errors.multiplier"></error-messages>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.schema_condition" label="Element conditie Schema.org"/>
        <error-messages :errors="errors.schema_condition"></error-messages>
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
