<template>
  <cit-data-table
    :headers="headers"
    resource-path="product-qualities"
  >

    <template v-slot:actions>
      <create-form/>
    </template>

    <template v-slot:item.actions="{item}">

      <edit-form :item="item"/>
      <action-delete :action-url="`product-qualities/${item.id}`"/>

    </template>

  </cit-data-table>

</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create'
import EditForm from './Edit'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          sortable: false
        },
        {
          text: 'Coeficient inmultire',
          value: 'multiplier',
          sortable: false,
          width: '100px',
          align: 'center'
        }
      ]
    }
  }
}
</script>
